<template>
    <div class="container">
        <div class="commonList" v-show="dataList.length>0">
            <table>
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>举报者</td>
                        <td>举报内容</td>
                        <td>举报信息</td>
                        <td>时间</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in dataList" :key="index">
                        <td>{{item.id}}</td>
                        <td>{{item.user_id}}</td>
                        <td>{{item.report_type}}</td>
                        <td>{{item.content}}</td>
                        <td>{{item.create_time}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="commonNotData" v-show="isNotData">没有数据！</div>

    </div>
</template>

<script>

import { reportListPost } from '@/service/api'

export default {
    data(){
        return {
            dataList:[],
            isLoading:false,
            isNotData:false,
        }
    },
    mounted(){
        this.loadData();
    },
    methods:{
        loadData(){

            var params = {"currentPage":this.currentPage,"pageSize":this.pageSize};

            this.isLoading = true;
            reportListPost(params).then(res => {
                if(res.status_code == 0){
                    this.dataList = res.data;
                    this.isNotData = this.dataList.length<=0?true:false;
                }
            }).finally(()=>{
               this.isLoading = false;
            });
        }
    }
    
}
</script>